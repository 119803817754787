import React from "react";
import BlackIcon from "../../images/partnershipAssets/DurationBlack.png";
import InIcon from "../../images/partnershipAssets/DurationIn.png";
import EnIcon from "../../images/partnershipAssets/DurationEn.png";
import JaIcon from "../../images/partnershipAssets/DurationJa.png";
import { useLanguage } from "../../languageContext";

const Duration = ({ showBlack = false, text }) => {
  const currentLanguage = useLanguage();
  return showBlack ? (
    <div className="duration-icon">
      <img src={showBlack} alt="Duration Icon presented to get duration." />{" "}
      {text}
    </div>
  ) : currentLanguage === "en" ? (
    <div className="duration-icon">
      <img src={EnIcon} alt="Duration Icon presented to get duration." /> {text}
    </div>
  ) : currentLanguage === "in" ? (
    <div className="duration-icon">
      <img src={InIcon} alt="Duration Icon presented to get duration." /> {text}
    </div>
  ) : currentLanguage === "ja" ? (
    <div className="duration-icon">
      <img src={JaIcon} alt="Duration Icon presented to get duration." /> {text}
    </div>
  ) : (
    <div className="duration-icon">
      <img src={showBlack} alt="Duration Icon presented to get duration." />{" "}
      {text}
    </div>
  );
};
export default Duration;
